<template>
    <transition-group name="view" tag="div">
    <div v-if="page == 'vitalik'">

      <div class="container">
        <br>
        <div class="row">
          <div class="col" style="text-align: left;">
            <h1 v-on:click="page = 'top'"><i class="bi bi-list-ol"></i></h1>
          </div>
          <div class="col" style="text-align: end;">
            <h1 v-on:click="page = 'transfer'"><i class="bi bi-send"></i></h1>
          </div>
        </div>

        <h1>🪙 {{ score }} </h1>
        <small v-if="err">у вас не установлен username, игра не будет засчитывать ваш баланс</small><br>

        <img class="img-fluid zoomable" src="vitalik.png" v-on:click="tap()">
        <div v-if="!dailyBonusUsed">
          <br>
          <br>
          <button class="btn btn-success btn-lg form-control" v-on:click="this.toggleDailyBonus()">🎁 Ежедневный бонус +1000 🎁</button>
          <br>
        </div>
        <br>
        <br>

      </div>
      <div class="container secondary-bg">
        <br>
        <div class="card" v-on:click="page ='casino'">
          <div class="card-body">
            <div class="row">
              <div class="col" style="text-align: left;">
                <h4>🎰 Казино</h4>
                Выигрывай <b>Виталик Коины</b> в рулетке
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="card" v-on:click="page ='bets'">
          <div class="card-body">
            <div class="row">
              <div class="col" style="text-align: left;">
                <h4>🔮 Рынки предсказаний</h4>
                Делай ставки на реальные события в <b>Виталик Коинах</b>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="card" v-on:click="this.openChannel()">
          <div class="card-body">
            <div class="row">
              <div class="col" style="text-align: left;">
                <h4>📢 Канал с новостями</h4>
                Подпишись и получи <b>100 000</b> 🪙 в течении 24 часов
              </div>
            </div>
          </div>
        </div>
        <br>

        <div class="card" v-on:click="this.copyFriendLink()">
          <div class="card-body">
            <div class="row">
              <div class="col" style="text-align: left;">
                <h4>👬 Пригласи друзей</h4>
                <span v-if="!friendLinkCopied">Пригласи человека по своей ссылке и вы оба получите по <b>50 000</b> 🪙</span>
                <span v-if="friendLinkCopied">Ссылка скопирована! Отправьте её другу!</span>
              </div>
            </div>
          </div>
        </div>


        <br>
        <br>
        <br>

      </div>
    </div>

    <div v-if="page == 'transfer'">
      <div class="container">
        <br>
          <div class="row">
            <div class="col" style="text-align: left;">
              <h1 v-on:click="page = 'vitalik'"><i class="bi bi-x-circle-fill"></i></h1>
            </div>
          </div>

          <div v-if="!showSentMessage">
            <h1>💸</h1>
            <br>
            <h4>Здесь Вы можете отправить Виталик Коины другому пользователю.</h4>
            <br>
          </div>

          <div v-if="showSentMessage">
            <h1>✅</h1>
            <br>
            <h3>{{ this.transferAmount}} Виталик Коинов успешно отправлены пользователю @{{ this.transferAddress }} !</h3>
            <br>
            <button class="btn btn-success btn-lg form-control" v-on:click="clearTransferWindow()">Отправить ещё</button>
            <br>
            <br>
          </div>

      </div>

      <div class="container secondary-bg" v-if="!showSentMessage">

        <br>
        <span class="error">{{ transferAmountError }} <br></span>
        <input type="text" class="form-control form-control-lg" placeholder="количество" v-model="transferAmount" @focus="showMobileKeyboard"
        @keyup.enter="hideMobileKeyboard"/>
        <span class="error">{{ transferAddressError }}<br></span>
        <input type="text" class="form-control form-control-lg" placeholder="telegram ник получателя без @" v-model="transferAddress" @focus="showMobileKeyboard"
        @keyup.enter="hideMobileKeyboard"/>
        <br>
        <button class="btn btn-success btn-lg form-control" v-if="this.transferAddressError == '' && this.transferAmountError == ''" v-on:click="transferCoins()">Отправить</button>
        <button class="btn btn-success btn-lg form-control disabled" v-if="this.transferAddressError != '' || this.transferAmountError != ''">Отправить</button>

      </div>
    </div>

    <div v-if="page == 'top'">
      <div class="container">
        <br>
        <div class="row">
          <div class="col" style="text-align: left;">
            <h1 v-on:click="page = 'vitalik'"><i class="bi bi-x-circle-fill"></i></h1>
          </div>
        </div>
        <h4>🎪 Топ держателей Виталика:</h4>
      <br>
      </div>
      <div class="container secondary-bg">
      <br>

      <div v-for="(item, index) in Object.entries(this.top)
                .sort((a, b) => b[1] - a[1])
                .map(([key, value]) => ({ key, value }))" :key="index" style="padding: 5px;">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col" style="text-align: left; white-space: nowrap;">
                <b>{{ index + 1 }}</b>. <b>@{{ item.key }}</b>
              </div>
              <div class="col" style="text-align: end; white-space: nowrap;">
                <b>{{ item.value }} 🪙</b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br>
      <br>

      <img class="img-fluid" src="message.png">

      <br>
      <br>
      </div>
    </div>

    <div v-if="page == 'casino'">

      <div class="container">
      <br>
        <div class="row">
            <div class="col" style="text-align: left;">
              <h1 v-on:click="page = 'vitalik'"><i class="bi bi-x-circle-fill"></i></h1>
            </div>
        </div>
      <br>
      <img src="vitalik.png" class="rotate" width="50" height="50" v-if="vitalikLoader">
      <h1 v-if="!vitalikLoader">выпало {{ this.betResult }}</h1>
      <p>{{this.betText}}</p>
      <br>
      </div>
      <div class="container secondary-bg">
      <br>
      <div class="row">
        <div class="col-4 bet green bet-selected" v-if="betNumber == 0" v-on:click="betNumber = 0">
          0
        </div>
        <div class="col-4 bet green" v-if="betNumber != 0" v-on:click="betNumber = 0">
          0
        </div>

        <div class="col-4 bet red bet-selected" v-if="betNumber == 1" v-on:click="betNumber = 1">
          1
        </div>
        <div class="col-4 bet red" v-if="betNumber != 1" v-on:click="betNumber = 1">
          1
        </div>

        <div class="col-4 bet green bet-selected" v-if="betNumber == 2" v-on:click="betNumber = 2">
          2
        </div>
        <div class="col-4 bet green" v-if="betNumber != 2" v-on:click="betNumber = 2">
          2
        </div>
      </div>

      <div class="row">
        <div class="col-4 bet red bet-selected" v-if="betNumber == 3" v-on:click="betNumber = 3">
          3
        </div>
        <div class="col-4 bet red" v-if="betNumber != 3" v-on:click="betNumber = 3">
          3
        </div>

        <div class="col-4 bet green bet-selected" v-if="betNumber == 4" v-on:click="betNumber = 4">
          4
        </div>
        <div class="col-4 bet green" v-if="betNumber != 4" v-on:click="betNumber = 4">
          4
        </div>

        <div class="col-4 bet red bet-selected" v-if="betNumber == 5" v-on:click="betNumber = 5">
          5
        </div>
        <div class="col-4 bet red" v-if="betNumber != 5" v-on:click="betNumber = 5">
          5
        </div>
      </div>
      <br>
      <br>
      <div class="row">
        <div class="col" style="text-align: left;">
          <span class="section-header">Сумма ставки</span>
        </div>
        <div class="col" style="text-align: end;" >
          <u class="section-header" v-on:click="betAmount = this.score">Поставить все</u>
        </div>
      </div>
      <input type="text" class="form-control" placeholder="сумма ставки" v-model="betAmount" @focus="showMobileKeyboard"
      @keyup.enter="hideMobileKeyboard"/>
      <br>
      Вы ставите {{ betAmount }} Виталик Коинов на число {{ betNumber }}.
      <br>
      <br>
      <button class="btn btn-success btn-lg form-control" v-on:click="bet()">Играть</button>
      <br>
      <br>
      <pre v-html="this.log"></pre>

      </div>

    </div>

    <div v-if="page == 'bets'">

      <div class="container">
        <br>
        <div class="row">
          <div class="col" style="text-align: left;">
            <h1 v-on:click="page = 'vitalik'"><i class="bi bi-x-circle-fill"></i></h1>
          </div>
          <div class="col" style="text-align: end;">
            <h1>
              <i class="bi bi-question-circle-fill mx-3" v-on:click="page = 'howBetsWorks'"></i>
              <i class="bi bi-plus-circle-fill" v-on:click="page = 'createBet'"></i>
            </h1>
          </div>
        </div>
        <h4>🔮 Рынки предсказаний</h4>
      <br>
      </div>

      <div class="container secondary-bg">
      <br>
      <span v-for="bet in this.bets" v-bind:key="bet">
        <div style="text-align: left; word-break: break-all;" class="m-1" v-if="bet != null && bet.name != null"><u><b>{{ bet.name }}</b></u></div>
        <div style="text-align: left; word-break: break-all;" class="m-1" v-if="bet != null && bet.description != null">{{ bet.description }}</div>
        <div style="text-align: left; word-break: break-all;" class="m-1" v-if="bet != null && bet.description != null"><i>автор: @{{ bet.author }}</i></div>
        <div class="progress-stacked m-1">

          <div class="progress" role="progressbar" :style="{width: bet.val1cap_percent + '%'}">
            <div class="progress-bar">Да {{ bet.val1cap_percent.toFixed(0) }}%</div>
          </div>

          <div class="progress" role="progressbar" :style="{width: bet.val2cap_percent + '%'}">
            <div class="progress-bar bg-success">Нет {{ bet.val2cap_percent.toFixed(0) }}%</div>
          </div>

        </div>
        <div class="m-3">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-success" data-bs-toggle="collapse" :href="'#collapse'+bet.id" role="button" aria-expanded="false">Показать ставки</button>
            <button type="button" class="btn btn-success" data-bs-toggle="collapse" :href="'#makeABid'+bet.id" role="button" aria-expanded="false">Сделать ставку</button>
            <button type="button" class="btn btn-success" v-if="bet.author == this.username" data-bs-toggle="collapse" :href="'#closeABid'+bet.id" role="button" aria-expanded="false">Закрыть сделку</button>
          </div>
        </div>

        <div class="collapse m-1" :id="'closeABid'+bet.id">
          <p>Как автор данной сделки, ты должен её закрыть как только данное событие произошло. После этого средства будут распределны между участниками сделки.</p>

          <p>Пока этот функционал не доступен.</p>

          <button class="btn btn-primary btn-sm m-1 disabled" v-on:click="closeABid(bet.id, 1)">Закрыть как ДА</button>
          <button class="btn bg-success btn-sm m-1 disabled" style="color: white" v-on:click="closeABid(bet.id, 2)">Закрыть как НЕТ</button>

        </div>

        <div class="collapse m-1" :id="'makeABid'+bet.id">
          <div class="input-group mb-3">
            <input type="text" class="form-control input-group-text" placeholder="сумма ставки" :id="'input'+bet.id">
            <button class="btn btn-primary btn-lg" v-on:click="makeABid(bet.id, 1)">Да</button>
            <button class="btn bg-success btn-lg" style="color: white" v-on:click="makeABid(bet.id, 2)">Нет</button>
          </div>
        </div>
        <div class="collapse m-1" :id="'collapse'+bet.id">
            <div class="card card-body">
              <div class="row">
                <div class="col">
                  <span><b>Да</b></span><br>
                  <span v-for="bid in bet.bids" v-bind:key="bid">
                    <span v-if="bid.value == 1 && bid.username == this.username" style="color: green;">@{{ bid.username }} {{ bid.amount }}<br></span>
                    <span v-if="bid.value == 1 && bid.username != this.username">@{{ bid.username }} {{ bid.amount }}<br></span>
                  </span>
                </div>

                <div class="col">
                  <span><b>Нет</b></span><br>
                  <span v-for="bid in bet.bids" v-bind:key="bid">
                    <span v-if="bid.value == 2 && bid.username == this.username" style="color: green;">@{{ bid.username }} {{ bid.amount }}<br></span>
                    <span v-if="bid.value == 2 && bid.username != this.username">@{{ bid.username }} {{ bid.amount }}<br></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        <hr>
      </span>
      </div>
    </div>

    <div v-if="page == 'createBet'">

      <div class="container">
        <br>
        <div class="row">
          <div class="col" style="text-align: left;">
            <h1 v-on:click="page = 'bets'"><i class="bi bi-backspace-fill"></i></h1>
          </div>
        </div>
        <h4>🔮 Создать новый рынок</h4>
      <br>
      </div>

      <div class="container secondary-bg">
        <br>
        <input type="text" class="form-control form-control-lg" placeholder="название" v-model="newMarketName">
        <br>
        <input type="text" class="form-control form-control-lg" placeholder="короткое описание" v-model="newMarketDescr">
        <br>
        <button class="btn btn-success btn-lg form-control" v-on:click="createMarket()">Создать</button>
      </div>
    </div>

    <div v-if="page == 'howBetsWorks'">
      <div class="container">
        <br>
        <div class="row">
          <div class="col" style="text-align: left;">
            <h1 v-on:click="page = 'bets'"><i class="bi bi-backspace-fill"></i></h1>
          </div>
        </div>
        <h4>🔮 Как работают рынки предсказаний?</h4>
      <br>
      </div>
      <div class="container secondary-bg">
        <br>
        <p>Пользователи вкладывают свои <b>Виталик Коины</b> в разные рынки, в зависимости от того, во что они верят. Например, если много людей думает, что завтра Виталик уснёт на тусовке, они вкладывают свои <b>Виталик Коины</b> в рынок <b>"Уснёт ли Виталик на тусовке"</b>.</p>

        <p>Если Виталик действительно уснёт, люди, которые вложили <b>Виталик Коины</b> в этот рынок, поделят между собой все <b>Виталик Коины</b>, которые там накопились. Но чем больше людей угадало, тем меньше монеток достанется каждому.</p>
      </div>
    </div>
    </transition-group>
</template>

<script>
import JSConfetti from 'js-confetti'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'VitalikCoin',
  data() {
    return {
      page: "vitalik",
      score: 0,
      username: "durov",
      confetti: null,
      err: false,
      top: null,
      transferAmount: "",
      transferAddress: "",
      errorText: "",
      transferAmountError: " ",
      transferAddressError: " ",
      allUsers: [],
      showSentMessage: false,
      dailyBonusUsed: true,
      betNumber: 0,
      betAmount: 0,
      betText: "",
      betResult: 0,
      vitalikLoader: false,
      log: "",
      friendLink: "",
      friendLinkCopied: false,
      bets: [],
      newMarketName: "",
      newMarketDescr: ""
    };
  },
  watch: {
    transferAmount: {
    handler() {
      if(this.transferAmount > this.score){
        this.transferAmountError = "Вы пытаетесь вывести больше чем у Вас есть."
      } else if (this.transferAmount < 0 ) {
        this.transferAmountError = "Ты охуел?"
      } else {
        this.transferAmountError = ""
      }
    }
    },
    transferAddress: {
      handler(){
        if(Object.keys(this.top).includes(this.transferAddress)){
          this.transferAddressError = ""
        } else {
          this.transferAddressError = "Такого пользователя нет";
        }
      }
    }
  },
  mounted() {
    window.Telegram.WebApp.ready();
    const user = window.Telegram.WebApp.initDataUnsafe?.user;

    if (user && user.username) {
        this.username = user.username
    } else {
        this.err = true
    }

    this.friendLink = "https://t.me/vitalikcoinbot?start=" + this.username
    this.getTop()
    this.getBets()
    this.updateCasinoLog()

    setInterval(() => {
      this.getTop()
      this.updateCasinoLog()
      this.getBets()
    }, 5000)

    this.confetti = new JSConfetti()

    // check daily bonus
    this.dailyBonusUsed = this.checkDailyBonus()

    setInterval(() => {
      this.addScore();
    }, 1000);
  },
  methods: {
  async getTop() {
      try {
        const res = await fetch('https://vitalik-api.drusha.me/top.php');
        if (!res.ok) {
          this.top = null
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.json();
        this.top = data
        for (const property in this.top) {
          if (property == this.username){
            this.score = parseInt(this.top[property])
          }
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
  },
  sendToast(message){
      createToast(message,
        {
          hideProgressBar: true,
          showCloseButton: true,
          swipeClose: true,
          position: 'top-center',
        })
  },
  async createMarket() {
      try {
        const res = await fetch('https://vitalik-api.drusha.me/createBet.php?name='+this.newMarketName+'&author='+this.username+'&descr='+this.newMarketDescr);
        if (!res.ok) {
          console.log('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        console.log(data)
        this.getBets()
        this.sendToast("Ура! Создан новый рынок!")
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
  },
  async makeABid(bet, value) {
      try {
        let amount = document.getElementById("input"+bet).value
        if( amount > this.score){
          this.sendToast("Вы пытаетесь поставить больше чем у Вас есть.")
          return
        }
        //https://vitalik-api.drusha.me/bet.php?bet=1&username=drushadrusha&value=1&amount=1000
        const res = await fetch('https://vitalik-api.drusha.me/bet.php?bet='+bet+'&username='+this.username+'&value='+value+'&amount='+amount);
        if (!res.ok) {
          this.sendToast("Сделка не принята! "+res.statusText)
          console.log('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        console.log(data)
        this.sendToast("Ваша ставка принята!")
        this.getBets()
      } catch (error) {
        this.sendToast("Сделка не принята! "+error)
        console.error('There was a problem with the fetch operation:', error);
      }
  },
  async getBets() {
      try {
        const res = await fetch('https://vitalik-api.drusha.me/getBets.php');
        if (!res.ok) {
          this.bets = []
          console.log('Network response was not ok ' + res.statusText);
        }
        const data = await res.json();
        this.bets = data
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
  },
  copyFriendLink(){
    this.friendLinkCopied = true
    navigator.clipboard.writeText(this.friendLink);
    setTimeout(()=>{
      this.friendLinkCopied = false
    }, 5000)
  },
  async transferCoins(){
    try {
        const res = await fetch('https://vitalik-api.drusha.me/transfer.php?to='+this.transferAddress+'&from='+this.username+'&amount='+this.transferAmount);
        if (!res.ok) {
          console.log('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        if(data == "ok"){
          this.showSentMessage = true
          setTimeout(() => {
            this.clearTransferWindow()
          }, 5000)
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
  },
  clearTransferWindow(){
    this.transferAddress = ""
            this.transferAmount = ""
            this.transferAddressError = ""
            this.transferAmountError = ""
            this.showSentMessage = false
  },
  tap(){
    this.addScore()
    this.confetti.addConfetti({
                emojis: ['🪙', '💵'],
                confettiNumber: 1,
             })

             const img = document.querySelector('.zoomable');
      img.classList.add('zoom');
      setTimeout(() => img.classList.remove('zoom'), 50);
  },
  checkDailyBonus() {
    const dailyBonusLastDate = localStorage.getItem("dailyBonusDate");
    const currentDate = new Date().toISOString().split('T')[0]; // Текущая дата в формате YYYY-MM-DD

    if (dailyBonusLastDate === currentDate) {
        // Пользователь уже прокликал бонус сегодня
        console.log("bonus already used today")
        return true;
    } else {
      console.log("bonus not used")
        // Пользователь не прокликал бонус сегодня
        return false;
    }
  },
  hideMobileKeyboard() {
    //this.$refs.input.blur();
  },
  showMobileKeyboard() {
    //this.$refs.input.focus();
  },
  async toggleDailyBonus(){
    const currentDate = new Date().toISOString().split('T')[0];
    localStorage.setItem("dailyBonusDate", currentDate);
    this.dailyBonusUsed = true

    this.score = this.score + 1000;
    await fetch('https://vitalik-api.drusha.me/dailyBonus.php?username='+this.username);

    this.confetti.addConfetti({
                emojis: ['🪙', '💵'],
                confettiNumber: 30,
             })
  },
  async addScore(){
    this.score = this.score + 3;
    await fetch('https://vitalik-api.drusha.me/tap.php?username='+this.username);
  },
  async updateCasinoLog(){
    const res = await fetch('https://vitalik-api.drusha.me/casinolog.txt');
        const data = await res.text();
        this.log = data
  },
  openChannel(){
    window.Telegram.WebApp.openTelegramLink("https://t.me/vitalikcoin_official")
  },
  async bet(){
    this.vitalikLoader = true;
    this.betText = ""
    if(this.betAmount > this.score){
          alert("Нельзя поставить больше чем у Вас есть")
          this.vitalikLoader = false
          return
        }
    try {
        const res = await fetch('https://vitalik-api.drusha.me/casino.php?username='+this.username+'&amount='+this.betAmount+'&bet='+this.betNumber);
        if (!res.ok) {
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.betResult = data
        setTimeout(() => {
          this.vitalikLoader = false
          if (data == this.betNumber) {
          this.betText = "Вы выиграли "+this.betAmount+" Виталик Коинов!"
          this.confetti.addConfetti({
                emojis: ['🪙', '💵'],
                confettiNumber: 30,
             })
        } else {
          this.betText = "Вы проиграли "+this.betAmount+" Виталик Коинов!"
        }

        this.updateCasinoLog()

        }, 2000)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }

  }
  }
}
</script>

<style>
        body{
            background-color: var(--tg-theme-secondary-bg-color);
            color: var(--tg-theme-text-color);
            text-align: center;
            font-family: "Ubuntu", sans-serif;
            font-weight: 300;
            font-style: normal;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
        }
        .butt:active{
            transform: scale(1.1);
        }

        button {
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;
        }

.img-fluid {
  transition: transform 0.2s ease-in-out;
}

/*.zoomable {
  animation: rainbow-border 2s ease-in-out infinite;
}*/

@keyframes rainbow-border {
    0% { filter: drop-shadow(0 0 10px red); }
    14% { filter: drop-shadow(0 0 10px orange); }
    28% { filter: drop-shadow(0 0 10px yellow); }
    42% { filter: drop-shadow(0 0 10px green); }
    57% { filter: drop-shadow(0 0 10px blue); }
    71% { filter: drop-shadow(0 0 10px indigo); }
    85% { filter: drop-shadow(0 0 10px violet); }
    100% { filter: drop-shadow(0 0 10px red); }
}

.btn{
  border-radius: 16px;
}

.form-control {
  border-radius: 16px;
}

.container{
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

.zoom {
  transform: scale(1.2);
}

.nav-pills .nav-link.active {
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  font-size: 0.8rem;
}

.nav-link {
  font-size: 0.8rem;
}

.error{
  text-align: end !important;
  color:red;
}

.view-enter-active {
  transition: all 0.35s ease-out;
}

.view-leave-active {
  transition: all 0.01s cubic-bezier(1, 0.5, 0.8, 1);
}

.view-enter-from,
.view-leave-to {
  transform: translateX(500px);
  opacity: 0;
}

.bet {
  padding: 1rem;
  border: 4px double gray;
  border-radius: 16px;
}
.green {
  background: black;
  color: white;
}

.red {
  background: rgb(176, 0, 0);
  color:white;
}

.bet-selected {
  opacity: 30%;
}

.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.secondary-bg {
  background: var(--tg-theme-secondary-bg-color) !important;
}

.card {
  background: var(--tg-theme-section-bg-color);
  color: var(--tg-theme-text-color);
  border-radius: 16px;
}

.card-body {
  color: var(--tg-theme-text-color);
}

.btn-success {
  background: var(--tg-theme-button-color) !important;
  border-color: var(--tg-theme-button-color) !important;
  color: var(--tg-theme-button-text-color) !important;
}

.section-header{
  color: var(--tg-theme-section-header-text-color);
  text-transform: uppercase;
  font-weight: 100;
}

.progress {
  height: 2.5em !important;
  border-radius: 16px;
}

.progress-stacked{
  height: 2.5em !important;
  border-radius: 16px;
}

.wordbreak {
  word-break: normal;
}

</style>
